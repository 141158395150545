import React, { createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Exception, AuthorizationException, EntitlementLimitReachedException, Rental, Entitlement } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { usePrevious } from 'react-use';
import { UserContext } from './UserContext';
import PlaylistItem from '../models/PlaylistItem';
import PlaybackStartingState from '../components/PlaybackStarting/PlaybackStartingState';
import { useEntitlement } from '../hooks/useEntitlement';
import { useRental } from '../hooks/useRental';
import Device from '../models/Device';
import MoviePlaylistItem from '../models/MoviePlaylistItem';

const { INITIALIZING, PREPARING, TRANSITIONING, PIN, RENTAL, ENTITLEMENT, ENTITLEMENT_LIMIT, ERROR } = PlaybackStartingState;

type PlaybackContextProps = {
	playlistItem: PlaylistItem;
	prevPlaylistItem: PlaylistItem;
	entitlement: Entitlement;
	forceEntitlement: () => void;
	rental: Rental;
	exception: Exception;
	startPlaylist: (items: PlaylistItem[]) => void;
	itemsPlayed: number;
	next: () => void;
	hasNext: boolean;
	reset: () => void;
	positionToPlayFrom: number;
	initialPlaybackPosition: number;
	prevInitialPlaybackPosition: number;
	setInitialPlaybackPosition: (position: number) => void;
	playbackStartingState: PlaybackStartingState;
	setPlaybackStartingState: (playbackStartingState: PlaybackStartingState) => void;
	isReady: boolean;
	setNextPlaybackState: () => void;
	allowedAgeRating: number;
	setAllowedAgeRating: (ageRating: number) => void;
};

export const PlaybackContext = createContext<Partial<PlaybackContextProps>>({});

export const PlaybackContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
	const { isLoggedIn } = useContext(UserContext);

	const [playlist, setPlaylist] = useState<PlaylistItem[]>([]);
	const [playlistIndex, setPlaylistIndex] = useState(-1);
	const [itemsPlayed, setItemsPlayed] = useState(0);
	const [exception, setException] = useState<Exception>(null);
	const [initialPlaybackPosition, setInitialPlaybackPosition] = useState<number>(-1);
	const prevInitialPlaybackPosition = usePrevious(initialPlaybackPosition);
	const [playbackStartingState, setPlaybackStartingState] = useState<PlaybackStartingState>(INITIALIZING);
	const [allowedAgeRating, setAllowedAgeRating] = useState<number>(0);

	const playlistItem = useMemo(() => playlist[playlistIndex], [playlist, playlistIndex]);
	const prevPlaylistItem = usePrevious(playlistItem);
	const hasNext = useMemo(() => !!playlist[playlistIndex + 1], [playlist, playlistIndex]);
	const positionToPlayFrom = useMemo(() => playlistItem?.getPositionToPlayFrom() ?? 0, [playlistItem]);
	const isMoviePlayback = useMemo(() => playlistItem instanceof MoviePlaylistItem, [playlistItem]);

	const { entitlement, exception: entitlementException, forceEntitlement } = useEntitlement({ playlistItem, playbackStartingState });
	const { rental, exception: rentalException } = useRental({ playlistItem, playbackStartingState });
	useEffect(() => {
		if (prevPlaylistItem !== playlistItem && prevPlaylistItem instanceof MoviePlaylistItem) {
			// Save position on stop.
			prevPlaylistItem.savePosition(prevPlaylistItem.lastKnownPosition, true);
		}
	}, [playlistItem, prevPlaylistItem]);

	const isReady = useMemo(() => {
		if (playbackStartingState !== null && playbackStartingState !== PREPARING) return false;

		if (!playlistItem || !playlistItem.isPrepared) return false;

		return !!entitlement;
	}, [entitlement, playbackStartingState, playlistItem]);

	const setNextPlaybackState = useCallback(() => {
		let nextPlaybackState = null;

		switch (playbackStartingState) {
			case TRANSITIONING:
				nextPlaybackState = INITIALIZING;
				break;
			case INITIALIZING:
				nextPlaybackState = PIN;
				break;
			case PIN:
				nextPlaybackState = RENTAL;
				break;
			case ERROR:
				nextPlaybackState = INITIALIZING;
				break;
			case RENTAL:
			case ENTITLEMENT_LIMIT:
				nextPlaybackState = ENTITLEMENT;
				break;
			case ENTITLEMENT:
				nextPlaybackState = PREPARING;
				break;
			default:
			// do nothing
		}

		// console.log(`Transitioning playback state: ${playbackStartingState} -> ${nextPlaybackState}`);

		if (nextPlaybackState) {
			setPlaybackStartingState(nextPlaybackState);
		}
	}, [playbackStartingState]);

	useEffect(() => {
		if (playlistItem && playbackStartingState === TRANSITIONING && (!isMoviePlayback || !rental) && !entitlement) {
			setNextPlaybackState();
		}
	}, [entitlement, isMoviePlayback, playbackStartingState, playlistItem, rental, setNextPlaybackState]);

	useEffect(() => {
		(async () => {
			if (!playlistItem || playbackStartingState !== INITIALIZING) return;

			try {
				Device.checkBrowserSupport();

				await playlistItem.preparePlayback();
				setNextPlaybackState();
			} catch (e) {
				setException(e);
			}
		})();
	}, [initialPlaybackPosition, itemsPlayed, playbackStartingState, playlistItem, positionToPlayFrom, prevPlaylistItem, setNextPlaybackState]);

	useEffect(() => {
		if (entitlementException) {
			setException(entitlementException);
		}
	}, [entitlementException]);

	useEffect(() => {
		if (rentalException) {
			setException(rentalException);
		}
	}, [rentalException]);

	useEffect(() => {
		if (exception) {
			let nextPlaybackStartingState = null;

			if (exception instanceof AuthorizationException) {
				if (isLoggedIn) {
					nextPlaybackStartingState = ERROR;
				}
			} else if (exception instanceof EntitlementLimitReachedException) {
				nextPlaybackStartingState = ENTITLEMENT_LIMIT;
			} else {
				nextPlaybackStartingState = ERROR;
			}

			if (nextPlaybackStartingState) {
				setPlaybackStartingState(nextPlaybackStartingState);
			}
		}
	}, [exception, isLoggedIn, playlistItem]);

	useEffect(() => {
		if (playbackStartingState === RENTAL && (rental || !playlistItem?.requiresRental())) {
			setNextPlaybackState();
		}
	}, [playbackStartingState, playlistItem, rental, setNextPlaybackState]);

	useEffect(() => {
		if (playbackStartingState === ENTITLEMENT && entitlement) {
			setNextPlaybackState();
		}
	}, [entitlement, playbackStartingState, setNextPlaybackState]);

	const startPlaylist = useCallback((items) => {
		setException(null);
		setPlaybackStartingState(INITIALIZING);
		setPlaylist(items);
		setItemsPlayed(0);
		setPlaylistIndex(0);
		setInitialPlaybackPosition(-1);
		setAllowedAgeRating(0);
	}, []);

	const next = useCallback(() => {
		setPlaybackStartingState(TRANSITIONING);
		setItemsPlayed(itemsPlayed + 1);
		setInitialPlaybackPosition(-1);
		setPlaylistIndex(playlistIndex + 1);
	}, [itemsPlayed, playlistIndex]);

	const reset = useCallback(() => {
		startPlaylist([]);
	}, [startPlaylist]);

	const value = useMemo(
		() => ({
			playlistItem,
			prevPlaylistItem,
			entitlement,
			forceEntitlement,
			rental,
			exception,
			startPlaylist,
			itemsPlayed,
			next,
			hasNext,
			reset,
			positionToPlayFrom,
			initialPlaybackPosition,
			prevInitialPlaybackPosition,
			setInitialPlaybackPosition,
			playbackStartingState,
			setPlaybackStartingState,
			isReady,
			setNextPlaybackState,
			allowedAgeRating,
			setAllowedAgeRating,
		}),
		[
			allowedAgeRating,
			entitlement,
			exception,
			forceEntitlement,
			hasNext,
			initialPlaybackPosition,
			prevInitialPlaybackPosition,
			isReady,
			itemsPlayed,
			next,
			playbackStartingState,
			playlistItem,
			positionToPlayFrom,
			prevPlaylistItem,
			rental,
			reset,
			setNextPlaybackState,
			startPlaylist,
		]
	);

	return <PlaybackContext.Provider value={value}>{children}</PlaybackContext.Provider>;
};
