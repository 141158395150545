import styled, { keyframes } from 'styled-components';
import { sharedFontStyles } from '../../ui/core/Typography/typography.styles';
import { media } from '../../ui/utils/media';

const fadeIn = keyframes`
	from {
		visibility: hidden;
		opacity: 0;
	}
	to {
		visibility: visible;
		opacity: 1;
	}
`;

const spin = keyframes`
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
`;

const shake = keyframes`
	0% { transform: translateX(0) }
	25% { transform: translateX(5px) }
	50% { transform: translateX(-5px) }
	75% { transform: translateX(5px) }
	100% { transform: translateX(0) }
`;

const fromTop = keyframes`
	from {
		opacity: 0;
		transform: translateY(-40%);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
`;

const popIn = keyframes`
	from {
		opacity: 0;
		transform: scale(1.2);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
`;

const Container = styled.div`
	display: flex;
`;

export const ChangeProfileContainer = styled.div<{ show: boolean; hasError: boolean }>`
	display: flex;
	flex-direction: column;
	position: absolute;
	justify-content: space-between;
	align-items: center;
	padding: 24px 0px;
	overflow: hidden;

	width: 100%;
	height: 100%;

	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;

	background: ${({ theme }) => theme.colors.tealMinus70};
	box-shadow: ${({ theme }) => theme.misc.boxShadowHigh};
	border-radius: 0px;
	animation: ${({ hasError }) => hasError && shake} 0.2s ease-in;

	opacity: ${({ show }) => (show ? 1 : 0)};
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
	transition: all 0.3s ease-in;

	${media.desktopUp} {
		width: 640px;
		max-height: 800px;
		border-radius: 12px;
	}

	${Container} {
		display: ${({ show }) => (show ? 'flex' : 'none')};
	}
`;

const ContentWrapper = styled.div<{ imageSelect?: boolean }>`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

export const ConfirmWrapper = styled(ContentWrapper)`
	align-items: center;
	justify-content: space-between;
	text-align: center;
	padding: 0px 24px;
	width: 70%;
	gap: 38px;
`;

export const FormWrapper = styled(ContentWrapper)`
	align-items: center;
	justify-content: space-between;

	transform: translateX(${({ imageSelect }) => (imageSelect ? -100 : 0)}%);
	transition: all 0.5s ease;
`;

export const ImageSelectWrapper = styled(ContentWrapper)`
	position: absolute;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
	::-webkit-scrollbar {
		display: none;
	}
	max-height: 100%;
	gap: 16px;
	padding: 0px 24px;
	transform: translateX(${({ imageSelect }) => (imageSelect ? 100 : 0)}%);
	transition: all 0.5s ease;

	${media.tabletPortraitUp} {
		padding: 0px 96px;
	}

	${media.tabletLandscapeUp} {
		padding: 0px 128px;
		gap: 24px;
	}

	${media.desktopUp} {
		padding: 0px 24px;
		gap: 32px;
	}
`;

export const ContentContainer = styled(Container)`
	position: relative;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const ContainerHead = styled(Container)`
	flex-direction: column;
	justify-content: center;
	padding: 8px 32px;

	width: 100%;

	${media.tabletPortraitUp} {
		width: 70%;
	}

	${media.desktopUp} {
		width: 100%;
	}
`;

export const CloseButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 5px;
	height: 0px;
`;
export const HeadTitle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px 0px 0px;
	gap: 8px;
	animation: ${fromTop} 0.3s ease-in;
`;
export const ProfileImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 12px;
`;

export const Overlay = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: ${({ theme }) => theme.colors.teal};
	opacity: 0;
	transition: all 0.5s ease;
`;

export const BorderOverlay = styled.div<{ isSelected?: boolean }>`
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 12px;
	border: 5px solid ${({ theme }) => theme.colors.orange};
	opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
	transition: all 0.5s ease;
`;

export const EditButton = styled.div<{ show?: boolean }>`
	display: ${({ show }) => (show ? 'flex' : 'none')};
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 30px;
	height: 30px;
	margin: 10px;
	border-radius: 4px;
	transition: all 0.5s ease;
	animation: ${popIn} 0.2s ease-in;

	svg {
		transform: scale(1.5);
	}

	${media.tabletLandscapeUp} {
		width: 44px;
		height: 44px;
		margin: 16px;
	}
`;

export const BackButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 30px;
	height: 30px;
	margin: 10px;
	border-radius: 4px;
	transition: all 0.5s ease;
	animation: ${popIn} 0.2s ease-in;
	cursor: pointer;

	svg {
		transform: scale(1.5);
	}

	:hover {
		svg {
			transform: scale(1.7);
		}
	}
`;

export const ImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100px;
	height: 100px;
	overflow: hidden;
	border-radius: 12px;

	background: ${({ theme }) => theme.colors.tealMinus60};
	transition: all 0.5s ease;
	animation: ${popIn} 0.2s ease-in;

	:hover {
		cursor: pointer;
		${Overlay} {
			opacity: 0.7;
		}
		${EditButton} {
			display: flex;
		}
	}

	${media.mobileUp} {
		width: 120px;
		height: 120px;
	}
	${media.tabletPortraitUp} {
		width: 140px;
		height: 140px;
	}
	${media.tabletLandscapeUp} {
		width: 160px;
		height: 160px;
	}
	${media.desktopUp} {
		width: 180px;
		height: 180px;
	}
	${media.desktopXLargeUp} {
		width: 208px;
		height: 208px;
	}

	img {
		width: 100%;
		height: 100%;
	}
`;

export const FixedImageContainer = styled(ImageContainer)`
	animation: ${fromTop} 0.3s ease-in;

	:hover {
		cursor: default;
	}
`;

export const SmallText = styled.p<{ error?: boolean }>`
	${sharedFontStyles};
	font-weight: 500;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.15px;
	color: ${({ theme, error }) => (error ? theme.colors.orange : theme.colors.tealPlus20)};
	transform: scale(${({ error }) => (error ? 1.1 : 1)});
	transition: all 0.5s ease;
`;
export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 8px 48px;
	gap: 20px;

	width: 100%;

	${media.tabletPortraitUp} {
		width: 70%;
	}

	${media.desktopUp} {
		width: 100%;
	}
`;
export const Line = styled.div`
	width: 100%;
	height: 1px;
	background: rgba(255, 255, 255, 0.1);
`;
export const ContainerFooter = styled(Container)`
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 8px 24px;
	gap: 8px;

	width: 100%;

	${media.mobileUp} {
		justify-content: space-between;
		padding: 8px 48px;
	}

	${media.tabletPortraitUp} {
		justify-content: space-between;
		padding: 8px 48px;
		width: 70%;
		gap: 24px;
	}

	${media.desktopUp} {
		width: 100%;
	}
`;

export const RightContainer = styled.div`
	display: flex;
	gap: 8px;

	${media.tabletPortraitUp} {
		gap: 16px;
	}
`;

export const ButtonContent = styled.div<{ disabled?: boolean }>`
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
	animation: ${popIn} 0.2s ease-in;
`;

export const BackBlur = styled.div<{ show: boolean }>`
	display: ${({ show }) => (show ? 'flex' : 'none')};
	position: fixed;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.misc.blackMoreTransparent};
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(10px);
	animation: ${fadeIn} 0.2s ease-in;
`;

export const LoadingBlur = styled(BackBlur)`
	z-index: 15;
`;

export const LoadingRing = styled.div`
	display: inline-block;
	width: 130px;
	height: 130px;

	:after {
		content: ' ';
		display: block;
		width: 130px;
		height: 130px;
		border-radius: 50%;
		border: 10px solid ${({ theme }) => theme.colors.teal};
		border-color: ${({ theme }) => theme.colors.tealPlus40} transparent ${({ theme }) => theme.colors.tealPlus40} transparent;
		animation: ${spin} 1.2s linear infinite;
	}
`;

export const SuccessContainer = styled.div<{ wasSuccess: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 130px;
	height: 130px;
	overflow: hidden;
	border-radius: 50%;

	background: ${({ theme, wasSuccess }) => (wasSuccess ? theme.colors.teal : theme.colors.orange)};
	transition: all 0.5s ease;
	animation: ${popIn} 0.2s ease-in;

	svg {
		width: 50%;
		height: 50%;
	}
`;
