import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { useRouter } from 'next/router';
import { useProfiles, SET_PROFILE_MUTATION, Profile, useAuthenticatedMutation, ProfileType } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { useTheme } from 'styled-components';
import { HeaderMenuIconContainer } from './HeaderMenuIconContainer';
import { ProfileImage } from '../ProfileImage';
import { Icon, ICON_TYPE } from '../../IconComponent/IconComponent';
import { Divider } from '../../Divider';
import { UserContext } from '../../../contexts/UserContext';
import { MobileScreensContext } from '../../../contexts/MobileScreensContext';
import { useHasMounted } from '../../../hooks/useHasMounted';
import { TransparentSpacer, HeaderMenuContainer, ProfileKeyContainer, Profiles, ProfileText, MenuItems, MenuItemText, ProfileSelectedIcon, ProfilesItems } from './HeaderMenu.styles';

export const HeaderMenu: React.FC = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { width } = useWindowSize();
	const { screenLayout } = useTheme();
	const { user, logout, setProfile } = useContext(UserContext);
	const { toggleProfile } = useContext(MobileScreensContext);
	const { profiles, loadingProfiles } = useProfiles();
	const [mutateSetProfile] = useAuthenticatedMutation(SET_PROFILE_MUTATION);
	const hasMounted = useHasMounted();

	const handleClick = (name) => {
		switch (name) {
			case 'settings':
				router.push('/settings');
				break;
			case 'changeProfile':
				router.push('/profiles');
				break;
			case 'mySite':
				break;
			case 'logout':
				logout();
				break;
			default:
				break;
		}
	};

	const handleProfileClick = useCallback(
		async (profile: Profile) => {
			try {
				if (width < screenLayout.tabletLandscape) toggleProfile();
				const result = await mutateSetProfile({ variables: { profileId: profile.id } });
				await setProfile(result.data.setProfile, profile);
			} catch (error) {
				console.log(error);
			} finally {
				router.push('/');
			}
		},
		[mutateSetProfile, router, screenLayout, setProfile, toggleProfile, width]
	);

	if (!hasMounted) {
		return null;
	}

	return (
		<>
			<TransparentSpacer />
			<HeaderMenuContainer>
				<ProfilesItems>
					{!loadingProfiles &&
					profiles?.filter((p) => user?.selectedProfile?.id !== p?.id)
						.map((profile) => (
							<ProfileKeyContainer key={`${profile.id}`} onClick={() => handleProfileClick(profile)}>
								<Profiles>
									<ProfileImage image={profile.image} />
									<ProfileText truncate>{profile.name.trim() !== '' ? profile?.name.split(' ')[0] : t('Profiles.Home')}</ProfileText>
								</Profiles>
								<Divider />
							</ProfileKeyContainer>
						))}
				</ProfilesItems>
				{user?.selectedProfile?.type !== ProfileType.KIDS && (
					<MenuItems onClick={() => handleClick('changeProfile')}>
						<HeaderMenuIconContainer>
							<Icon type={ICON_TYPE.EDIT_PROFILE} />
						</HeaderMenuIconContainer>
						<MenuItemText truncate>{t('HeaderMenu.ChangeProfiles')}</MenuItemText>
					</MenuItems>
				)				}
				<MenuItems onClick={() => handleClick('settings')}>
					<HeaderMenuIconContainer>
						<Icon type={ICON_TYPE.SETTINGS_SM} />
					</HeaderMenuIconContainer>
					<MenuItemText truncate>{t('HeaderMenu.Settings')}</MenuItemText>
				</MenuItems>
				<MenuItems onClick={() => handleClick('logout')}>
					<HeaderMenuIconContainer>
						<Icon type={ICON_TYPE.EXIT} />
					</HeaderMenuIconContainer>
					<MenuItemText truncate>{t('HeaderMenu.Logout')}</MenuItemText>
				</MenuItems>
			</HeaderMenuContainer>
		</>
	);
};
