// @ts-nocheck child errors from lib
import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { APIContextProvider } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { theme } from '../ui/theme/theme';
import '../utils/Localization';
import { ErrorBoundary } from '../utils/Bugsnag';
import { page } from '../utils/Analytics';
import '../index.css';
import '../App.scss';
import '../scenes/CategoryListScene/styles.scss';
import '../components/ContentInfo/style.scss';
import '../components/Player/style.scss';
import '../components/Player/PlaybackInformation/style.scss';
import '../components/Player/PlaybackInformation/PlayStateIcon/style.scss';
import '../components/Player/PlaybackInformation/ProgramsList/styles.scss';
import '../components/Player/PlaybackInformation/ProgressBar/styles.scss';
import '../components/Player/PlaybackInformation/ProgressContainer/styles.scss';
import { UserContextProvider } from '../contexts/UserContext';
import { RegisterContextProvider } from '../contexts/RegisterContext';
import { ForgotContextProvider } from '../contexts/ForgotContext';
import { PlaybackContextProvider } from '../contexts/PlaybackContext';
import { ApolloClientProvider } from '../api/ApolloClient';
import { AnalyticsContextProvider } from '../contexts/AnalyticsContext';
import { SettingsContextProvider } from '../contexts/SettingsContext';
import { MobileScreensContextProvider } from '../contexts/MobileScreensContext';
import appleIcon57x57 from '../../public/images/apple-icon-57x57.png';
import appleIcon60x60 from '../../public/images/apple-icon-60x60.png';
import appleIcon72x72 from '../../public/images/apple-icon-72x72.png';
import appleIcon76x76 from '../../public/images/apple-icon-76x76.png';
import appleIcon114x114 from '../../public/images/apple-icon-114x114.png';
import appleIcon120x120 from '../../public/images/apple-icon-120x120.png';
import appleIcon144x144 from '../../public/images/apple-icon-144x144.png';
import appleIcon152x152 from '../../public/images/apple-icon-152x152.png';
import appleIcon180x180 from '../../public/images/apple-icon-180x180.png';
import androidIcon192x192 from '../../public/images/android-icon-192x192.png';
import favIcon16x16 from '../../public/images/favicon-16x16.png';
import favIcon32x32 from '../../public/images/favicon-32x32.png';
import favIcon96x96 from '../../public/images/favicon-96x96.png';
import apiClient from '../api/APIClient';
import Layout from '../components/Layout/Layout';

const App = ({ Component, pageProps }) => {
	const { t } = useTranslation();
	const router = useRouter();

	useEffect(() => {
		const onRouteChange = () => {
			page();
			document.body.classList.remove('disable-overscroll');
		};

		router.events.on('routeChangeComplete', onRouteChange);

		return () => {
			router.events.off('routeChangeComplete', onRouteChange);
		};
	}, [router]);

	return (
		<>
			<Head>
				<meta charSet={'utf-8'} />
				<title>{t('Main.Title')}</title>
				<meta name={'viewport'} content={'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'} />
				<link rel={'apple-touch-icon'} sizes={'57x57'} href={appleIcon57x57.src} />
				<link rel={'apple-touch-icon'} sizes={'60x60'} href={appleIcon60x60.src} />
				<link rel={'apple-touch-icon'} sizes={'72x72'} href={appleIcon72x72.src} />
				<link rel={'apple-touch-icon'} sizes={'76x76'} href={appleIcon76x76.src} />
				<link rel={'apple-touch-icon'} sizes={'114x114'} href={appleIcon114x114.src} />
				<link rel={'apple-touch-icon'} sizes={'120x120'} href={appleIcon120x120.src} />
				<link rel={'apple-touch-icon'} sizes={'144x144'} href={appleIcon144x144.src} />
				<link rel={'apple-touch-icon'} sizes={'152x152'} href={appleIcon152x152.src} />
				<link rel={'apple-touch-icon'} sizes={'180x180'} href={appleIcon180x180.src} />
				<link rel={'icon'} type={'image/png'} sizes={'192x192'} href={androidIcon192x192.src} />
				<link rel={'icon'} type={'image/png'} sizes={'16x16'} href={favIcon16x16.src} />
				<link rel={'icon'} type={'image/png'} sizes={'32x32'} href={favIcon32x32.src} />
				<link rel={'icon'} type={'image/png'} sizes={'96x96'} href={favIcon96x96.src} />
			</Head>
			<ErrorBoundary>
				<ApolloClientProvider>
					<APIContextProvider client={apiClient}>
						<UserContextProvider>
							<SettingsContextProvider>
								<RegisterContextProvider>
									<ForgotContextProvider>
										<PlaybackContextProvider>
											<AnalyticsContextProvider>
												<ThemeProvider theme={theme}>
													<MobileScreensContextProvider>
														<Layout>
															<Component {...pageProps} />
														</Layout>
													</MobileScreensContextProvider>
												</ThemeProvider>
											</AnalyticsContextProvider>
										</PlaybackContextProvider>
									</ForgotContextProvider>
								</RegisterContextProvider>
							</SettingsContextProvider>
						</UserContextProvider>
					</APIContextProvider>
				</ApolloClientProvider>
			</ErrorBoundary>
		</>
	);
};

export default App;
