import React, { FC } from 'react';

import { ThemeColorProp } from '../../ui/theme/theme';
import add from '../../../public/icons/add.svg';
import arrowDown from '../../../public/icons/arrow_down.svg';
import arrowLeft from '../../../public/icons/arrow_left.svg';
import arrowRight from '../../../public/icons/arrow_right.svg';
import bio from '../../../public/icons/bio.svg';
import born from '../../../public/icons/born.svg';
import close from '../../../public/icons/close.svg';
import edit from '../../../public/icons/edit.svg';
import frelsi from '../../../public/icons/frelsi.svg';
import grid from '../../../public/icons/grid.svg';
import heima from '../../../public/icons/heima.svg';
import help from '../../../public/icons/help.svg';
import hide from '../../../public/icons/hide.svg';
import info from '../../../public/icons/info.svg';
import ithrottir from '../../../public/icons/ithrottir.svg';
import leita from '../../../public/icons/leita.svg';
import list from '../../../public/icons/list.svg';
import listinn from '../../../public/icons/listinn.svg';
import menu from '../../../public/icons/menu.svg';
import play from '../../../public/icons/play.svg';
import remove from '../../../public/icons/remove.svg';
import rewind from '../../../public/icons/rewind.svg';
import search from '../../../public/icons/search.svg';
import show from '../../../public/icons/show.svg';
import sjonvarp from '../../../public/icons/sjonvarp.svg';
import soundOn from '../../../public/icons/sound_on.svg';
import soundOff from '../../../public/icons/sound_off.svg';
import stillingar from '../../../public/icons/stillingar.svg';
import thaettir from '../../../public/icons/thaettir.svg';
import editProfile from '../../../public/icons/edit_profile.svg';
import user from '../../../public/icons/user.svg';
import settings from '../../../public/icons/settings.svg';
import exit from '../../../public/icons/exit.svg';
import myndlykill from '../../../public/icons/myndlykill.svg';
import appleTv from '../../../public/icons/apple_tv.svg';
import computer from '../../../public/icons/computer.svg';
import smartdevices from '../../../public/icons/smartdevice.svg';
import syn from '../../../public/icons/syn.svg';
import phoneRing from '../../../public/icons/phone_ring.svg';
import facebookColor from '../../../public/icons/facebook_color.svg';
import instagramColor from '../../../public/icons/instagram_color.svg';
import youtubeColor from '../../../public/icons/youtube_color.svg';
import stod2Logo from '../../../public/logos/logo/stod2.svg';
import stod2PlusLogo from '../../../public/logos/logo/stod2_plus.svg';
import arrowRightL from '../../../public/icons/arrow_right_big.svg';
import olisDeild from '../../../public/icons/olis.svg';
import stod2present from '../../../public/icons/presenter.svg';
import stod3 from '../../../public/icons/STOD3.svg';
import stod2bio from '../../../public/icons/STOD2BIO.svg';
import sport from '../../../public/icons/SPORT.svg';
import sport2 from '../../../public/icons/SPORT2.svg';
import sport3 from '../../../public/icons/SPORT3.svg';
import sport4 from '../../../public/icons/SPORT4.svg';
import golf from '../../../public/icons/SPORT5.svg';
import esport from '../../../public/icons/ESPORT.svg';

const iconMapper: IconMapper = {
	ADD: add,
	ARROW_DOWN: arrowDown,
	ARROW_LEFT: arrowLeft,
	ARROW_RIGHT: arrowRight,
	ARROW_RIGHT_L: arrowRightL,
	BIO: bio,
	CHILDREN: born,
	CLOSE: close,
	EDIT: edit,
	CATCHUP: frelsi,
	GRID: grid,
	HOME: heima,
	HELP: help,
	HIDE: hide,
	INFO: info,
	SPORTS: ithrottir,
	SEARCH_SM: search,
	LIST: list,
	MY_LIST: listinn,
	MENU: menu,
	PLAY: play,
	REMOVE: remove,
	REWIND: rewind,
	SEARCH_LG: leita,
	SHOW: show,
	TV: sjonvarp,
	SOUND_ON: soundOn,
	SOUND_OFF: soundOff,
	SETTINGS_LG: stillingar,
	EPISODES: thaettir,
	USER: user,
	EDIT_PROFILE: editProfile,
	SETTINGS_SM: settings,
	EXIT: exit,
	SET_TOP_BOX: myndlykill,
	APPLE_TV: appleTv,
	COMPUTER: computer,
	SMARTDEVICES: smartdevices,
	SYN: syn,
	PHONE_RING: phoneRing,
	FACEBOOK_COLOR: facebookColor,
	YOUTUBE_COLOR: youtubeColor,
	INSTAGRAM_COLOR: instagramColor,
	STOD2_LOGO: stod2Logo,
	STOD2_PLUS_LOGO: stod2PlusLogo,
	OLIS_DEILD: olisDeild,
	PRESENT: stod2present,
	STOD3: stod3,
	STOD2BIO: stod2bio,
	SPORT: sport,
	SPORT2: sport2,
	SPORT3: sport3,
	SPORT4: sport4,
	GOLFSTODIN: golf,
	ESPORT: esport,
};

export enum ICON_TYPE {
	ADD = 'ADD',
	ARROW_DOWN = 'ARROW_DOWN',
	ARROW_LEFT = 'ARROW_LEFT',
	ARROW_RIGHT = 'ARROW_RIGHT',
	ARROW_RIGHT_L = 'ARROW_RIGHT_L',
	BIO = 'BIO',
	CHILDREN = 'CHILDREN',
	CLOSE = 'CLOSE',
	EDIT = 'EDIT',
	CATCHUP = 'CATCHUP',
	GRID = 'GRID',
	HOME = 'HOME',
	HELP = 'HELP',
	HIDE = 'HIDE',
	INFO = 'INFO',
	SPORTS = 'SPORTS',
	SEARCH_SM = 'SEARCH_SM',
	LIST = 'LIST',
	MY_LIST = 'MY_LIST',
	MENU = 'MENU',
	PLAY = 'PLAY',
	REMOVE = 'REMOVE',
	REWIND = 'REWIND',
	SEARCH_LG = 'SEARCH_LG',
	SHOW = 'SHOW',
	TV = 'TV',
	SOUND_ON = 'SOUND_ON',
	SOUND_OFF = 'SOUND_OFF',
	SETTINGS_LG = 'SETTINGS_LG',
	EPISODES = 'EPISODES',
	USER = 'USER',
	EDIT_PROFILE = 'EDIT_PROFILE',
	SETTINGS_SM = 'SETTINGS_SM',
	EXIT = 'EXIT',
	SET_TOP_BOX = 'SET_TOP_BOX',
	APPLE_TV = 'APPLE_TV',
	COMPUTER = 'COMPUTER',
	SMARTDEVICES = 'SMARTDEVICES',
	SYN = 'SYN',
	PHONE_RING = 'PHONE_RING',
	FACEBOOK_COLOR = 'FACEBOOK_COLOR',
	YOUTUBE_COLOR = 'YOUTUBE_COLOR',
	INSTAGRAM_COLOR = 'INSTAGRAM_COLOR',
	STOD2_LOGO = 'STOD2_LOGO',
	STOD2_PLUS_LOGO = 'STOD2_PLUS_LOGO',
	OLIS_DEILD = 'OLIS_DEILD',
	PRESENT = 'PRESENT',
	STOD3 = 'STOD3',
	STOD2BIO = 'STOD2BIO',
	SPORT = 'SPORT',
	SPORT2 = 'SPORT2',
	SPORT3 = 'SPORT3',
	SPORT4 = 'SPORT4',
	GOLFSTODIN = 'GOLFSTODIN',
	ESPORT = 'ESPORT',
}

type IconType = keyof typeof ICON_TYPE;
type IconMapper = Record<IconType, any>;

export type Props = {
	type: IconType;
	useHighLight?: boolean;
	highLightColor?: ThemeColorProp;
	height?: string;
};

export const Icon: FC<Props> = ({ type, useHighLight, highLightColor, height }) => {
	const SvgIcon = iconMapper[type];
	return <SvgIcon style={{ fill: useHighLight ? highLightColor : 'none', height }} />;
};

Icon.defaultProps = {
	useHighLight: false,
	highLightColor: 'none',
};
