import { DataToModelInterceptor, Request, LoginFailedException, Profile, useAuthenticatedMutation, ADD_USER_PROFILE_MUTATION } from '@vodafoneis/sjonvarpskjarni-js-lib';
import APIClient from '../api/APIClient';
import Device from './Device';
import { Client as Bugsnag } from '../utils/Bugsnag';

const USER_LOCAL_STORAGE_KEY = 'user';

export default class User {
	id: string;
	name: string;
	username: string;
	selectedProfile: Profile;
	profileId: string;
	accessToken: string;
	anonymous: boolean;

	constructor(data: object) {
		Object.assign(this, data);
	}

	/**
	 * Method to login. Username and password are optional and if left out the user will be logged in anonymously.
	 *
	 * @param username
	 * @param password
	 * @returns {Promise<User>}
	 */
	static async login({ username, password }: { username?: string; password?: string } = {}) {
		const browserName = Device.detectBrowserType();

		if (!browserName) {
			const message = 'Could not detect browser info';
			const exception = new Error(message);
			exception.name = message;
			console.error(message); // eslint-disable-line no-console
			Bugsnag.notify(exception);
			throw new LoginFailedException();
		}

		const deviceId = await Device.getOrGenerateDeviceId();

		if (!deviceId) {
			throw new LoginFailedException();
		}

		const body: { [key: string]: any } = {
			device: {
				id: deviceId,
				name: browserName,
				type: 'PC',
				model: browserName,
			},
		};

		const device = new Device({
			id: deviceId,
			name: browserName,
			type: 'PC',
			model: browserName,
		});

		if (username && password) {
			body.username = username;
			body.password = password;

			const loginQuery = new Request('login', {
				body,
				headers: {
					'Content-Type': 'application/json',
				},
			}).addInterceptor(new DataToModelInterceptor(User));

			const response = await APIClient.post(loginQuery);

			if (response.statusCode === 201) {
				const user = response.data;

				user.username = username;

				Device.setCurrentDevice(device);

				User.saveUser(user);

				return user;
			}

			throw new LoginFailedException();
		} else {
			Device.setCurrentDevice(device);

			return new User({ anonymous: true });
		}
	}

	static saveUser(user: object) {
		try {
			if (user) {
				window.localStorage.setItem('user', JSON.stringify(user));
			}
		} catch (exception) {}
	}

	static clearUser() {
		try {
			window.localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
		} catch (exception) {}
	}

	static retrieveUser() {
		try {
			const userData = window.localStorage.getItem('user');
			if (userData) {
				return new User(JSON.parse(userData));
			}
		} catch (exception) {}

		return null;
	}

	static verifyPin(pin: string) {
		return APIClient.post(
			new Request('user/pin', {
				body: {
					pin,
				},
			})
		);
	}

	static verifyPassword(username: string, password: string) {
		return APIClient.post(
			new Request('verify', {
				body: {
					username,
					password,
				},
			})
		);
	}

	static registrationVerifyPhone(phone: string, email: string, ssn: string) {
		return APIClient.post(
			new Request('user', {
				body: {
					phone,
					email,
					ssn,
				},
			})
		);
	}

	static registrationValidateCode(phone: string, email: string, ssn: string, code: string) {
		return APIClient.put(
			new Request('user', {
				body: {
					phone,
					email,
					ssn,
					code,
				},
			})
		);
	}

	static register(sessionId: string, password: string, confirmGdpr: boolean | null) {
		return APIClient.put(
			new Request('user', {
				body: {
					sessionId,
					password,
					confirmGdpr,
				},
			})
		);
	}

	static resetPasswordVerifyPhone(phone: string) {
		return APIClient.put(
			new Request('user/password', {
				body: {
					phone,
				},
			})
		);
	}

	static resetPasswordValidateCode(phone: string, code: string) {
		return APIClient.put(
			new Request('user/password', {
				body: {
					phone,
					code,
				},
			})
		);
	}

	static resetPassword(sessionId: string, username: string, password: string) {
		return APIClient.put(
			new Request('user/password', {
				body: {
					sessionId,
					username,
					password,
				},
			})
		);
	}

	static async updatePin(newPin: string, password: string) {
		try {
			const request = new Request('user/pin', {
				body: {
					newPin,
					password,
				},
			});

			const response = await APIClient.put(request);

			return response.isSuccess();
		} catch (exception) {}
		return false;
	}

	isAnonymous() {
		return this.anonymous || false;
	}
}
