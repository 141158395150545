import * as snippet from '@segment/snippet';
import { License, Playable, Episode, Series, Provider, Channel } from '@vodafoneis/sjonvarpskjarni-js-lib';
import PlaylistItem from '../models/PlaylistItem';
// eslint-disable-next-line import/no-cycle
import MoviePlaylistItem from '../models/MoviePlaylistItem';
import ChannelPlaylistItem from '../models/ChannelPlaylistItem';

const ANALYTICS_WRITE_KEY = process.env.ANALYTICS_WRITE_KEY; // eslint-disable-line
const NODE_ENV = process.env.NODE_ENV; // eslint-disable-line

export function renderSnippet() {
	const opts = {
		apiKey: ANALYTICS_WRITE_KEY,
		page: true,
	};

	if (NODE_ENV === 'development') {
		return snippet.max(opts);
	}

	return snippet.min(opts);
}

export function generateBasicInfoForPlaylistItem(playlistItem: PlaylistItem) {
	let basicInfo = {};

	if (playlistItem instanceof MoviePlaylistItem) {
		basicInfo = generateBasicContentInfo(playlistItem.movie, playlistItem.license);
	}

	if (playlistItem instanceof ChannelPlaylistItem) {
		basicInfo = generateBasicChannelInfo(playlistItem.channel);
	}

	return {
		playbackSessionId: playlistItem.getPlaybackSessionId(),
		profileId: undefined,
		...basicInfo,
	};
}

export function generateBasicContentInfo(content: Playable, license?: License) {
	// TODO: Get these constant from somewhere.
	const { MOVIE, TV } = { MOVIE: 'MOVIE', TV: 'TV' };

	const { id, title } = content;

	const provider = license?.provider;

	const basicInfo: { [key: string]: any } = {
		itemId: id,
		itemTitle: title,
		itemType: MOVIE,
		itemDuration: content.duration * 60,
		licenseId: license?.id,
		creditsPosition: content?.creditTiming?.start ?? null,
	};

	let episodeInfo: { [key: string]: any } = {};

	if (content instanceof Episode) {
		const { episodeNumber, episodeSeason, series } = content;
		const { id: seriesId, title: seriesTitle }: Series = series;

		basicInfo.itemType = TV;

		episodeInfo = {
			seriesId,
			seriesTitle,
			episodeNumber,
			episodeSeason,
		};
	}

	let providerInfo = {};

	if (provider) {
		const { id: providerId, title: providerTitle, type: providerType }: Provider = provider;

		providerInfo = {
			providerId,
			providerTitle,
			providerType,
		};
	}

	return {
		...basicInfo,
		...episodeInfo,
		...providerInfo,
	};
}

export function generateBasicChannelInfo(channel: Channel) {
	const { operatorRef, title } = channel;

	return {
		itemId: operatorRef,
		itemTitle: title,
		// @ts-ignore
		itemType: channel.channelType,
	};
}

export const identify = (user) => {
	// @ts-ignore
	if (window.analytics && user) {
		analytics.identify(user.id);
	}
};

export const page = () => {
	// @ts-ignore
	if (window.analytics) {
		analytics.page();
	}
};

export const track = (event, data) => {
	// @ts-ignore
	if (window.analytics) {
		analytics.track(event, data);
	}
};
