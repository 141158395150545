export enum AnalyticsEvent {
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
	CHANNEL_START = 'CHANNEL_START',
	CHANNEL_STOP = 'CHANNEL_STOP',
	CHANNEL_ERROR = 'CHANNEL_ERROR',
	VOD_START = 'VOD_START',
	VOD_STOP = 'VOD_STOP',
	VOD_ERROR = 'VOD_ERROR',
	RENTAL_SUCCESS = 'RENTAL_SUCCESS',
	RENTAL_ERROR = 'RENTAL_ERROR',
	RENTAL_ROLLBACK = 'RENTAL_ROLLBACK',
	MOVIE_VIEW = 'MOVIE_VIEW',
	SERIES_VIEW = 'SERIES_VIEW',
}
